/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { GET_STATIC_BLOCK } from "../../actions";
import axios from "axios";
import {
  apiUrl,
  appId,
  baseUrl,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
  dineinId,
} from "../Helpers/Config";

import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";
import fbscooter from "../../common/images/fb-scooter.png";
import takeawayImg from "../../common/images/fb-takeaway.png";
import dineImg from "../../common/images/fb-dinein.png";
import calenderImg from "../../common/images/fb-calender.png";
import menuImg from "../../common/images/fb-menu.png";
import outletslider from "../../common/images/outletslider.jpg";
import outletslider1 from "../../common/images/outletslider1.jpg";
import cateringImg from "../../common/images/catering.png";
var Parser = require("html-react-parser");
const settingsGallery = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  availabilityList: [],
  viewmenuBlk: "",
  staticblacks: [],
};
class Shoptype extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setAvilablityId: cookie.load("defaultAvilablityId"),
    };

    this.props.getStaticBlock();
    this.loadAvailability();
  }
  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var viewmenuBlk = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "view-menu") {
            viewmenuBlk = data.staticblocks_description;
            return "";
          }
        });
      }
      viewmenuBlk =
        viewmenuBlk !== "" && viewmenuBlk !== null
          ? Parser(viewmenuBlk)
          : viewmenuBlk;

      this.setState({
        staticblacks: PropsData.staticblack,
        viewmenuBlk: viewmenuBlk,
      });
    }
  }
  /*   componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 800);
  } */
  loadAvailability() {
    axios
      .get(apiUrl + "settings/get_availability?app_id=" + appId)
      .then((avail) => {
        if (avail.data.status === "ok") {
          this.setState({ availabilityList: avail.data.result_set });
        }
      });
  }
  checkActiveDivHd(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (this.state.setAvilablityId !== "") {
      if (this.state.setAvilablityId === avlType) {
        clsTxt += "active";
      }
    } else {
      if (
        (availability == avlType || this.state.setAvilablityId === avlType) &&
        orderOutletId !== "" &&
        orderOutletId !== undefined
      ) {
        clsTxt += "active";
      } else if (
        availability == avlType &&
        (avlType === cateringId || avlType === reservationId)
      ) {
        clsTxt += "active";
      }
    }

    return clsTxt;
  }
  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    // if (availability === reservationId || availability === dineinId) {
    //   return false;
    // }

    if (availability === "viewmenu") {
      popupIdtxt = "#viewmenu-popup";
    } else {
      var defaultAvilTy = cookie.load("defaultAvilablityId");

      if (defaultAvilTy !== availability) {
        var cartTotalItems = cookie.load("cartTotalItems");
        cartTotalItems =
          cartTotalItems != "" && cartTotalItems != undefined
            ? parseInt(cartTotalItems)
            : 0;
        if (defaultAvilTy === cateringId && cartTotalItems > 0) {
          this.setState({ nextavail: availability });

          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        } else if (
          cookie.load("orderOutletId") != "" &&
          cookie.load("orderOutletId") != undefined
        ) {
          this.setState({ nextavail: availability });
          $("#clearcartavailability").val(availability);
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      } else if (
        defaultAvilTy === availability &&
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        /*$.magnificPopup.close();
			this.props.history.push('/products');
      return false;*/
        if (availability !== reservationId) {
          this.setState({ nextavail: availability });
          $("#clearcartavailability").val(availability);
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      }

      var popupIdtxt = "";
      if (availability === deliveryId) {
        $(".delivery_outletpoup").find(".outlet_error").html("");
        popupIdtxt = "#delevery-postcode-popup";
      } else if (availability === pickupId) {
        popupIdtxt = "#takeaway-popup";
      } else if (availability === cateringId) {
        cookie.save("defaultAvilablityId", cateringId, { path: "/" });
        $.magnificPopup.close();
        this.props.history.push("/catering");
        return false;
      } else if (availability === reservationId) {
        cookie.save("defaultAvilablityId", reservationId, { path: "/" });
        $.magnificPopup.close();
        this.props.history.push("/reservation");
        return false;
      } else if (availability === dineinId) {
        cookie.save("defaultAvilablityId", dineinId, { path: "/" });
        $.magnificPopup.close();
        popupIdtxt = "#dinein-popup";
      }
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }
  sateValChange = (field, value) => {};
  displayAvailablity(availability_id) {
    var desc = "";
    if (
      availability_id !== "" &&
      this.state.availabilityList !== "" &&
      typeof this.state.availabilityList !== undefined &&
      typeof this.state.availabilityList !== "undefined"
    ) {
      this.state.availabilityList.map((item) => {
        if (item.av_id == availability_id) {
          desc =
            item.company_availability_web_desc !== "" &&
            item.company_availability_web_desc !== null
              ? Parser(item.company_availability_web_desc)
              : "";
        }
      });
    }
    return desc;
  }

  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <Slider {...settingsGallery}>
          <div>
            <img src={outletslider} alt="" />
          </div>
          <div>
            <img src={outletslider1} alt="" />
          </div>
        </Slider>
        <div className="outlet-lst-page">
          <div className="container-one cms-content">
            <div className="container">
              <h2>
                <span>Start</span> Your Order
              </h2>
              <div className="order_delivery_row outlet-alt-class">
                <div className="order_delivery_col">
                  <ul className="order_delivery_item">
                    <li className={this.checkActiveDivHd(deliveryId)}>
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={fbscooter} />
                      </div>
                      <h3>Delivery</h3>
                      {this.displayAvailablity(deliveryId)}
                      <a
                        className="button disbl_href_action"
                        href="/"
                        onClick={this.chooseAvailabilityFun.bind(
                          this,
                          deliveryId
                        )}
                      >
                        Continue
                      </a>
                    </li>
                    <li className={this.checkActiveDivHd(pickupId)}>
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={takeawayImg} />
                      </div>
                      <h3>Takeaway</h3>
                      {this.displayAvailablity(pickupId)}
                      <a
                        className="button disbl_href_action"
                        href="/"
                        onClick={this.chooseAvailabilityFun.bind(
                          this,
                          pickupId
                        )}
                      >
                        Continue
                      </a>
                    </li>
                    <li className={this.checkActiveDivHd(cateringId)}>
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={menuImg} />
                      </div>
                      <h3>View Menu</h3>
                      {this.state.viewmenuBlk}
                      <a
                        className="button disbl_href_action"
                        href="/"
                        onClick={this.chooseAvailabilityFun.bind(
                          this,
                          "viewmenu"
                        )}
                      >
                        Continue
                      </a>
                    </li>
                    <li className={this.checkActiveDivHd(dineinId)}>
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={dineImg} />
                      </div>
                      <h3>Dine In</h3>
                      {this.displayAvailablity(dineinId)}
                      <a
                        className="button disbl_href_action"
                        href="#"
                        onClick={this.chooseAvailabilityFun.bind(
                          this,
                          dineinId
                        )}
                      >
                        Continue
                      </a>
                    </li>
                    <li className={this.checkActiveDivHd(cateringId)}>
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={cateringImg} />
                      </div>
                      <h3>Catering</h3>
                      {this.displayAvailablity(cateringId)}
                      <a
                        className="button disbl_href_action"
                        href="#"
                        onClick={this.chooseAvailabilityFun.bind(
                          this,
                          cateringId
                        )}
                      >
                        Continue
                      </a>
                    </li>
                    <li className={this.checkActiveDivHd(reservationId)}>
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={calenderImg} />
                      </div>
                      <h3>Reservation</h3>
                      {this.displayAvailablity(reservationId)}
                      <a
                        className="button disbl_href_action"
                        href="/"
                        onClick={this.chooseAvailabilityFun.bind(
                          this,
                          reservationId
                        )}
                      >
                        Continue
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading1234"></div>
      </div>
    );
  }
}
const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Shoptype);
