/* eslint-disable */
import React, { Component } from 'react';
import { appId, apiUrl, deliveryId, cateringId, pickupId, apiUrlV2, CountryTxt, productNoImg, baseUrl, timThumpUrl } from "../../Helpers/Config"
import { getReferenceID, showLoader, hideLoader, callImage, showAlert, timeToConv12, dateConvFun, showPriceValue, showCustomAlert, stripslashes, jsUcfirstFun } from "../../Helpers/SettingHelper";
import cookie from 'react-cookies';
import axios from 'axios';
var qs = require('qs');
var Parser = require('html-react-parser');

import ClockImg from "../../../common/images/time.png";
			
export default class Packageupdate extends Component {


    constructor(props) {
        super(props);

        this.state = { productsdetail_edit: [], productfeilsdetail_edit: [], product_addons_edit: [], product_setup_edit: [], product_modifiers_edit: [], product_modifiers_price_edit: [], edit_flag : 0, edite_packageid : '', cart_packagedata: [],cart_maindata: [],cart_setupflg: 'Delivery'};
		
		
    }
	
	componentWillReceiveProps(chekoutProps) {
		
		if(chekoutProps.chekoutState.edite_packageid !== '') {
			this.setState({'edite_packageid': chekoutProps.chekoutState.edite_packageid, 'edit_flag': chekoutProps.chekoutState.edit_flag, 'cart_packagedata': chekoutProps.chekoutState.cart_packagedata, 'cart_maindata': chekoutProps.chekoutState.cart_maindata}, function () { this.getProductDetailByEdtId(); }.bind(this));
		}
		
	}
	
	getProductDetailByEdtId() {
		var  indFlg = this.state.edit_flag;
		var  kyIndx = 'Index_'+indFlg;
		var  productId = this.state.edite_packageid;
		var sateProductsdetail = this.state.productsdetail_edit;
		var sateProductModifr = this.state.product_modifiers_edit;
		var sateProductModifrPrice = this.state.product_modifiers_price_edit;
		var sateProductAddons = this.state.product_addons_edit;
		var sateProductSetup = this.state.product_setup_edit;
		var sateProductFeilsDet = this.state.productfeilsdetail_edit;
		
		axios.get(apiUrlV2 + "catering/cateringproducts_detail?app_id=" + appId + "&product_id=" + productId + "&availability=" + cateringId + "&status=A&").then(res => {
			    $('.editDivLoadingCls').fadeOut(500);
			    var proDet = Array();
				if (res.data.status === "success") {
					proDet['resultset'] = res.data.result_set;
					proDet['commonurl'] = res.data.common;
				}
				sateProductsdetail[kyIndx] = proDet;
				sateProductModifr[kyIndx] = Array();
				sateProductModifrPrice[kyIndx] = Array();
				sateProductAddons[kyIndx] = Array();
				sateProductSetup[kyIndx] = Array();
				sateProductFeilsDet[kyIndx] = Array();
				this.setState({productsdetail_edit: sateProductsdetail,productfeilsdetail_edit: sateProductFeilsDet,product_modifiers_edit: sateProductModifr,product_modifiers_price_edit: sateProductModifrPrice,product_addons_edit: sateProductAddons,product_setup_edit: sateProductSetup}, function () { this.setCartValueInProductFld_Edt(indFlg); }.bind(this));
			});
		
	}
	
	componentDidMount()
	{
		

	}
	
	setCartValueInProductFld_Edt(indFlg) {
		
		var proFieldEdtArr = Array();
		var proDetailEdtArr = Array();
		var modifiersEdtArr = Array();
		var addonsSetupEdtArr = Array();
		var setupDeliveryEdtArr = Array();
		var setupBuffetEdtArr = Array();
		var indKey = 'Index_'+indFlg;
		var stateProFldEdt = this.state.productfeilsdetail_edit;
		var cartPackageData = this.state.cart_packagedata;
		var cartMainData = this.state.cart_maindata;
		var stateProDet = this.state.productsdetail_edit;
		var productModfr= this.state.product_modifiers_edit;
		var productModfrPrice= this.state.product_modifiers_price_edit;
		var productAddons = this.state.product_addons_edit;
		var sateProductSetup = this.state.product_setup_edit;
		var overallMdfPrice = 0;
		
		if(Object.keys(stateProDet).length > 0) {
		   var temArr = (!(indKey in stateProDet)) ? Array() : stateProDet[indKey];
		   if(Object.keys(temArr).length > 0) {
			   proDetailEdtArr = (Object.keys(temArr['resultset']).length > 0) ? temArr['resultset'][0]:Array();
			   
			   modifiersEdtArr = proDetailEdtArr.modifiers;
			   addonsSetupEdtArr = proDetailEdtArr.addons_setup;
			   setupDeliveryEdtArr = proDetailEdtArr.delivery;
			   setupBuffetEdtArr = proDetailEdtArr.buffet;
		   }
	    }
		
		if(Object.keys(cartPackageData).length > 0) {
			proFieldEdtArr['brktimeSts'] = cartMainData.cart_breaktime_enable;
			proFieldEdtArr['brktimeCnt'] = cartMainData.cart_breaktime_count;
			proFieldEdtArr['brktimeLst'] = cartMainData.cart_breaktime_list;
			proFieldEdtArr['cartItemId'] = cartPackageData.cart_item_id;
			proFieldEdtArr['brktimeStr'] = cartPackageData.cart_item_breaktime_started;
			proFieldEdtArr['brktimeEnd'] = cartPackageData.cart_item_breaktime_ended;
			proFieldEdtArr['selectpaxcount'] = cartPackageData.cart_item_qty;
			proFieldEdtArr['productSpecialNotes'] = cartPackageData.cart_item_special_notes;
			
			var cartModifiers = cartPackageData.modifiers;
			var CartMdIndexArr = [];
			if(Object.keys(cartModifiers).length > 0) {
				for(var key in cartModifiers) {
					CartMdIndexArr[key] = cartModifiers[key].cart_modifier_id;
				}
			}
			
			if(Object.keys(modifiersEdtArr).length > 0) {
				for(var key in modifiersEdtArr) {
					var subKey = 'modifr_'+key;
					var allValEmpty = 'yes';
					var modfrMn = '';var tempArr = Array();
					var mdfIndx = $.inArray(modifiersEdtArr[key].pro_modifier_id, CartMdIndexArr);
					if(mdfIndx !== -1) {
						
						modfrMn = modifiersEdtArr[key].pro_modifier_id+'~~'+stripslashes(modifiersEdtArr[key].pro_modifier_name);
						
						var cartMdfValueArr = cartModifiers[mdfIndx].modifiers_values;
						var CartMdValueIndexArr = [];
						if(Object.keys(cartMdfValueArr).length > 0) {
							for(var key1 in cartMdfValueArr) {
								CartMdValueIndexArr[key1] = cartMdfValueArr[key1].cart_modifier_id;
							}
						}
						
						var mdfValueArr = modifiersEdtArr[key].modifiers_values;
						for(var key2 in mdfValueArr) {
							var mdfVlIndx = $.inArray(mdfValueArr[key2].pro_modifier_value_id, CartMdValueIndexArr);
							if(mdfVlIndx !== -1) {
								tempArr['mdfval_'+key2] = mdfValueArr[key2].pro_modifier_value_id+'~~'+stripslashes(mdfValueArr[key2].pro_modifier_value_name)+'~~1~~'+mdfValueArr[key2].pro_modifier_value_price;
								allValEmpty = 'No';
								overallMdfPrice += parseFloat(mdfValueArr[key2].pro_modifier_value_price);
							}
						}
					}
					
					if(allValEmpty == 'No') {
						var MdfArr = Array();
						MdfArr['modifiers'] = modfrMn;
						MdfArr['modifiervalues'] = tempArr;
						productModfr[indKey][subKey] = MdfArr;
					} else {
						productModfr[indKey][subKey] = Array();
					}
				}
			}
			
			productModfrPrice[indKey] = overallMdfPrice;
			
			var cartAddonsSetup = cartPackageData.addons_setup;
			var CartAddonsIndexArr = [];
			if(Object.keys(cartAddonsSetup).length > 0) {
				for(var key in cartAddonsSetup) {
					CartAddonsIndexArr[key] = cartAddonsSetup[key].cart_addon_setup_id;
				}
			}
			
			if(Object.keys(addonsSetupEdtArr).length > 0) {
				for(var key in addonsSetupEdtArr) {
					var subKey = 'addon_'+key;
					var tempArr = Array();var allValEmpty = 'yes';
					var addonIndx = $.inArray(addonsSetupEdtArr[key].product_catering_addons_setup_id, CartAddonsIndexArr);
					if(addonIndx !== -1) {
						
						var cartAddonsValueArr = cartAddonsSetup[addonIndx].addons_setup_values;
						var CartAddonsValueIndexArr = [];
						if(Object.keys(cartAddonsValueArr).length > 0) {
							for(var key1 in cartAddonsValueArr) {
								CartAddonsValueIndexArr[key1] = cartAddonsValueArr[key1].cart_addon_setup_val_id;
							}
						}
						
						var AddonsValueArr = addonsSetupEdtArr[key].addons_setup_values;
						for(var key2 in AddonsValueArr) {
							var addonVlIndx = $.inArray(AddonsValueArr[key2].cat_addonvalues_id, CartAddonsValueIndexArr);
							if(addonVlIndx !== -1) {
								var qtyInt = cartAddonsValueArr[addonVlIndx].cart_addon_setup_val_qty;
								tempArr[key2] = addonsSetupEdtArr[key].product_catering_addons_setup_id+"~~"+addonsSetupEdtArr[key].cat_addon_title+"~~"+addonsSetupEdtArr[key].cat_addon_selection+"~~"+AddonsValueArr[key2].cat_addonvalues_id+"~~"+AddonsValueArr[key2].cat_addonvalues_title+"~~"+AddonsValueArr[key2].cat_addonvalues_description+"~~"+AddonsValueArr[key2].cat_addonvalues_price+"~~"+qtyInt;
								allValEmpty = 'No';
							} else if(addonsSetupEdtArr[key].cat_addon_selection === 'multiple') {
								var qtyInt = 0;
								tempArr[key2] = addonsSetupEdtArr[key].product_catering_addons_setup_id+"~~"+addonsSetupEdtArr[key].cat_addon_title+"~~"+addonsSetupEdtArr[key].cat_addon_selection+"~~"+AddonsValueArr[key2].cat_addonvalues_id+"~~"+AddonsValueArr[key2].cat_addonvalues_title+"~~"+AddonsValueArr[key2].cat_addonvalues_description+"~~"+AddonsValueArr[key2].cat_addonvalues_price+"~~"+qtyInt;
							}
						}
						
					}
					
					if(allValEmpty == 'No') {
						productAddons[indKey][subKey] = tempArr;
					} else {
						productAddons[indKey][subKey] = Array();
					}
				}
			}
			
			var cartSetup = cartPackageData.setup;
			var cartSetupFlg = 'Delivery';
			var cartSetupIdArr = [];
			if(Object.keys(cartSetup).length > 0) {
				cartSetupFlg = cartSetup[0].cart_setup_type;
				for(var key in cartSetup) {
					cartSetupIdArr[key] = cartSetup[key].cart_setup_id;
				}
			}
			
			var subKey1 = 'delivery';
			var subKey2 = 'buffet';
			var deliveryArr = Array();
			var buffetArr = Array();
			
			if(cartSetupFlg === 'Delivery' && Object.keys(setupDeliveryEdtArr).length > 0) {
				var inctxt = 0;
				for(var key in setupDeliveryEdtArr) {
					var setVlIndx = $.inArray(setupDeliveryEdtArr[key].catering_delivery_setup_id, cartSetupIdArr);
					if(setVlIndx !== -1) {
						deliveryArr[inctxt] = setupDeliveryEdtArr[key].catering_delivery_setup_id+"~~"+setupDeliveryEdtArr[key].catering_delivery_title+"~~"+setupDeliveryEdtArr[key].catering_delivery_description+"~~"+setupDeliveryEdtArr[key].catering_delivery_price+"~~1~~Delivery";
						inctxt++;
					}
				}
			}
			
			if(cartSetupFlg === 'Buffet' && Object.keys(setupBuffetEdtArr).length > 0) {
				var inctxt = 0;
				for(var key in setupBuffetEdtArr) {
					var setVlIndx = $.inArray(setupBuffetEdtArr[key].catering_buffet_setup_id, cartSetupIdArr);
					if(setVlIndx !== -1) {
						buffetArr[inctxt] = setupBuffetEdtArr[key].catering_buffet_setup_id+"~~"+setupBuffetEdtArr[key].catering_buffet_title+"~~"+setupBuffetEdtArr[key].catering_buffet_description+"~~"+setupBuffetEdtArr[key].catering_buffet_price+"~~1~~Buffet";
						inctxt++;
					}
				}
			}
			sateProductSetup[indKey][subKey1] = deliveryArr;
			sateProductSetup[indKey][subKey2] = buffetArr;
			
			stateProFldEdt[indKey] = proFieldEdtArr;
		}
		
		
		this.setState({productfeilsdetail_edit: stateProFldEdt,product_modifiers_edit: productModfr, product_modifiers_price_edit: productModfrPrice,product_addons_edit: productAddons,product_setup_edit: sateProductSetup,cart_setupflg: cartSetupFlg}, function () { this.calculatedOverAllAmount_Edt(indFlg); }.bind(this));
	}
	
	calculatedOverAllAmount_Edt(indFlg) {
		
		   var proFieldArr = Array();
		   var proDetailArr = Array();
		   var AddonsArr = Array();
		   var SetupArr = Array();
		   var proMdfPrice = 0;
		   var stateProDet = this.state.productsdetail_edit;
		   var stateProFldDet = this.state.productfeilsdetail_edit;
		   var productAddons = this.state.product_addons_edit;
		   var productSetup = this.state.product_setup_edit;
		   var stateProMdfPrice = this.state.product_modifiers_price_edit;
		   var indKey = 'Index_'+indFlg;
		   var imageSource = '';
		   var productImage = '';
		   
		   if(Object.keys(stateProDet).length > 0) {
			   var temArr = (!(indKey in stateProDet)) ? Array() : stateProDet[indKey];
			   if(Object.keys(temArr).length > 0) {
				   proDetailArr = (Object.keys(temArr['resultset']).length > 0) ? temArr['resultset'][0]:Array();
				   imageSource = (Object.keys(temArr['commonurl']).length > 0) ? temArr['commonurl'].image_source:'';
				   ;
			   }
		   }
		   
		   if(Object.keys(stateProFldDet).length > 0) {
			   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
		   }
		   
		   if(Object.keys(productAddons).length > 0) {
			   AddonsArr = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
		   }
		   
		   if(Object.keys(productSetup).length > 0) {
			   SetupArr = (!(indKey in productSetup)) ? Array() : productSetup[indKey];
		   }
		   
		   if(Object.keys(stateProMdfPrice).length > 0) {
			   proMdfPrice = (!(indKey in stateProMdfPrice)) ? 0 : stateProMdfPrice[indKey];
		   }
		   
		   var minpaxcount = 0;
		   var minPaxAmount = 0;
		   var selectpaxcount = 0;
		   var productPrice = 0;
		   var productSubTotal = 0;
		   var productAddonsAmount = 0;
		   var productSetupAmount = 0;
		   var productGrandTotal = 0;
		   
		   if(Object.keys(proDetailArr).length > 0) {
		   
				productImage = (proDetailArr.product_thumbnail ==="" ) ? productNoImg : imageSource+"/"+proDetailArr.product_thumbnail;
		   
				minpaxcount = proDetailArr.product_min_pax;
				minPaxAmount = proDetailArr.product_min_amount;
				
				selectpaxcount = (!('selectpaxcount' in proFieldArr)) ? proDetailArr.product_min_pax : proFieldArr['selectpaxcount'];
		   
				productPrice = (parseFloat(proDetailArr.product_price) + parseFloat(proMdfPrice));
				
				productSubTotal = (parseFloat(selectpaxcount) * parseFloat(productPrice));
				
				
				for (var key in AddonsArr) {
					var AddonsValue = AddonsArr[key];
					for(var key2 in AddonsValue) {
						var ExpVarbl = AddonsValue[key2];
						var mytemparr = ExpVarbl.split("~~");
						if(parseInt(mytemparr[7]) > 0) {
						  productAddonsAmount += (parseInt(mytemparr[7]) * parseFloat(mytemparr[6]));
						}
					}
				}
				
				for (var key in SetupArr) {
					var SetupValue = SetupArr[key];
					if(Object.keys(SetupValue).length > 0) {
						for(var key2 in SetupValue) {
							var ExpVarbl = SetupValue[key2];
							var mytemparr = ExpVarbl.split("~~");
							if(parseInt(mytemparr[4]) > 0) {
							  productSetupAmount += (parseInt(mytemparr[4]) * parseFloat(mytemparr[3]));
							}
						}
					}
				}
		   
		   
				productGrandTotal = (parseFloat(productSubTotal) + parseFloat(productAddonsAmount) + parseFloat(productSetupAmount));
		   
				proFieldArr['product_id'] = proDetailArr.product_id;
				proFieldArr['product_name'] = stripslashes(proDetailArr.product_name);
				proFieldArr['product_sku'] = stripslashes(proDetailArr.product_sku);
				proFieldArr['product_slug'] = proDetailArr.product_slug;
				proFieldArr['productImage'] = productImage;
				proFieldArr['minpaxcount'] = minpaxcount;
				proFieldArr['minPaxAmount'] = minPaxAmount;
				proFieldArr['selectpaxcount'] = selectpaxcount;
				proFieldArr['productPrice'] = productPrice;
				proFieldArr['productSubTotal'] = productSubTotal;
				proFieldArr['productAddonsAmount'] = productAddonsAmount;
				proFieldArr['productSetupAmount'] = productSetupAmount;
				proFieldArr['productGrandTotal'] = productGrandTotal;
				
				stateProFldDet[indKey] = proFieldArr;
				this.setState({productfeilsdetail_edit: stateProFldDet});
				
		   }
		
	}
	
	gotoCategoryLst_Edt() {
		this.props.prpActiveTab(1);
		$("#PackageEditMdl").modal("hide");
	}
	
	getProductListHtml_inEd() {
		
		var pro_detailArr = this.state.productsdetail_edit;
		var cart_detailArr = this.state.cart_packagedata;
	    if(Object.keys(pro_detailArr).length > 0 && Object.keys(cart_detailArr).length > 0) {
			var indexFlgTxt = this.state.edit_flag;
			var selectedPgkId = this.state.edite_packageid;
			
			var catNameTemp = '';
			var indKeyTmp = 'Index_'+indexFlgTxt;
			var temArrTmp = (!(indKeyTmp in pro_detailArr)) ? Array() : pro_detailArr[indKeyTmp];
		    if(Object.keys(temArrTmp).length > 0) {
			   var proDetailArrTemp = (Object.keys(temArrTmp['resultset']).length > 0) ? temArrTmp['resultset'][0]:Array();
			   catNameTemp = (Object.keys(proDetailArrTemp).length > 0) ? stripslashes(proDetailArrTemp.catgory_name) : '';
		    }
			
			return (<div id="tab-pks-edit" className="tab-pane-sub">
			
							<div className="tab_mobrow1 pkselect_tabin">
									
								<div className="pkselected_filtersec">
								  <div className="row-replace">
		 
									<div className="col-sm-cls pkselected_package">
										{/*<label><i className="fa fa-th-large"></i> {cart_detailArr.cart_item_product_name}</label>*/}
										{(catNameTemp !== '')?<label className="buffet" onClick={this.gotoCategoryLst_Edt.bind(this)} style={{cursor: 'pointer'}}>{catNameTemp}</label>:''}
									</div>
									
									{(cart_detailArr.cart_item_breaktime_started !== '')?<div className="col-sm-cls pkselected_break text-right">
										<label>Break Time : {timeToConv12(cart_detailArr.cart_item_breaktime_started)}</label>
									</div>:''}
									
								  </div>         
								</div>
								
								{ this.getProductDetailHtml_Edt(indexFlgTxt,selectedPgkId) }
								
								{/* Update Action Start */}
								<div className="chk-payment-btn text-center">
						
									<div className="chk-payment-btn-row">	
										<div className="row-replace">
											<div className="col-md-cls">
												<a href="javascript:void(0)" onClick={this.goBckFun.bind(this)} className="button" title="Back">Back</a>
											</div>
											<div className="col-md-cls pgk-continue-div">
												<a href="javascript:void(0)" className="button" title="Continue" onClick={this.updateCartPackage.bind(this, indexFlgTxt)} >Continue</a>
											</div>
										</div>
										<div className="cart-success-msg alert alert_success" >Great choice! Item added to your cart.</div>
										<div className="cart-error-msg alert alert_danger" >Sorry!. Product Detaile was not valid.</div>
									</div>
									
								</div>
								{/* Update Action End */}
								
							</div>
					</div>);
		}
		
	}
	
	
	getProductDetailHtml_Edt(indFlg,selectedPgkId) {
		
		   var proFieldArr = Array();
		   var proDetailArr = Array();
		   var commonurlArr = Array();
		   var stateProDet = this.state.productsdetail_edit;
		   var stateProFldDet = this.state.productfeilsdetail_edit;
		   var indKey = 'Index_'+indFlg;
		   
		   if(Object.keys(stateProDet).length > 0) {
			   var temArr = (!(indKey in stateProDet)) ? Array() : stateProDet[indKey];
			   if(Object.keys(temArr).length > 0) {
				   proDetailArr = (Object.keys(temArr['resultset']).length > 0) ? temArr['resultset'][0]:Array();
				   commonurlArr = temArr['commonurl'];
			   }
		   }
		   
		   if(Object.keys(stateProFldDet).length > 0) {
			   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
		   }
		   
		
		if(Object.keys(proDetailArr).length > 0) {
			
			var selectpaxcount = (!('selectpaxcount' in proFieldArr)) ? proDetailArr.product_min_pax : proFieldArr['selectpaxcount'];
			var productSubTotal = (!('productSubTotal' in proFieldArr)) ? 0.00 : proFieldArr['productSubTotal'];
			var productAddonsAmount = (!('productAddonsAmount' in proFieldArr)) ? 0.00 : proFieldArr['productAddonsAmount'];
			var productSetupAmount = (!('productSetupAmount' in proFieldArr)) ? 0.00 : proFieldArr['productSetupAmount'];
			var productGrandTotal = (!('productGrandTotal' in proFieldArr)) ? 0.00 : proFieldArr['productGrandTotal'];
			var productSpecialNotes = (!('productSpecialNotes' in proFieldArr)) ? '' : proFieldArr['productSpecialNotes'];
			var productPrice = (!('productPrice' in proFieldArr)) ? 0 : proFieldArr['productPrice'];
			
			return (<div id={"proDetMainEdt-"+indFlg} data-packageminlimit={proDetailArr.product_min_select} data-packagemaxlimit={proDetailArr.product_max_select}>
			
					<div className="pkg_listsec bx_out">
						<div className="bx_in">
							<div className="pkglist_item pkglist_itemtable ">
								<div className="pkglist_img">
									{callImage(commonurlArr.image_source, proDetailArr.product_thumbnail, 422, 172, timThumpUrl)}
								</div>											
								<div className="pkglist_info">
									<div className="pkglist_infott">
										<h3>{stripslashes(proDetailArr.product_name)}</h3>
									</div>
									<p>{(proDetailArr.product_long_description != "")?proDetailArr.product_long_description:proDetailArr.product_short_description}</p>
								</div>
								<div className="pkglist_pricebx text-center">
									{(parseFloat(productPrice)>0) && <div><span className="display_block">Price Per Pax</span>
									<b className="display_block">{showPriceValue(productPrice)}</b></div>}
									<div className="qty_bx catqty_bx"> 
										<span className="qty_minus" onClick={this.updateProductPax_Edt.bind(this,indFlg,'minus',selectpaxcount,proDetailArr.product_min_pax)}>-</span>
										<input type="text" name="min_pax_count" value={selectpaxcount} readOnly /> 
										<span className="qty_plus" onClick={this.updateProductPax_Edt.bind(this,indFlg,'plus',selectpaxcount,proDetailArr.product_min_pax)}>+</span> 
									</div>
								</div>											
							</div>
						</div>
					</div>	
					
					{(Object.keys(proDetailArr.modifiers).length > 0)?<div className="bx_out package_checkbx">
							<div className="bx_in">
							    <div className="mdf_common_error" style={{color:'red',textAlign:'center',fontWeight:'bold',display:'none'}}></div>
								<div className="row package_checkrow product_modifiers_listtop"><div className="product_modifiers_listinner">{this.productModifiers_Edt(indFlg,proDetailArr.modifiers)}</div></div>
							</div>
						</div> : ""}
						
					{(Object.keys(proDetailArr.addons_setup).length > 0)?<div className="bx_out package_addonbx">
							<div className="bx_in">
								<h3 className="package_formtt"><span>Addons</span><small>(Choose  your addons )</small></h3>
								<ul className="package_formchecklist pkgaddon_checklist clearfix">{this.productAddonsSetup_Edt(indFlg,proDetailArr.addons_setup)}</ul>
							</div>
						</div> : ""}
					
					{(((Object.keys(proDetailArr.delivery).length > 0) || (Object.keys(proDetailArr.buffet).length > 0)) && (cookie.load('catering_eventType') !== 'hall'))?<div id={"pkg_setupbx_"+indFlg} className="bx_out package_setupbx">
							<div className="bx_in">
								<h3 className="package_formtt"><span>Setup</span><small>(Choose  your buffect setup)</small></h3>
					<div className="pkgsetup_list" id={"setupEdt_"+indFlg} >{this.productDeliverySetupHtml_Edt(indFlg,proDetailArr.delivery)} {this.productBuffetSetupHtml_Edt(indFlg,proDetailArr.buffet)}</div>
							</div>
						</div> : ""}
					 
					 <div className="bx_out package_descbx">
						<div className="bx_in">
							<h3 className="package_formtt package_formtt1"><span>Add Special Instruction</span></h3>
							<div className="form-group">
								<textarea className="form-control" placeholder="Type your Message here" value={productSpecialNotes} onChange={this.handleSpecialInstruction_Edt.bind(this,indFlg)} ></textarea>
							</div>
						</div>
					 </div>
					 
					 <div className="bx_out package_descbx" style={{padding:'0px'}}>
						<div className="bx_in ctr-price-item">
						
							<div className="row-replace pkg_total_box">
									<div className="cc-addon-subtotal">
										<div className="pkg_total_row">
											<div className="cc-addon-subtotal-lhs"><b>Subtotal</b></div>
											<div className="cc-addon-subtotal-rhs">: <span>{showPriceValue(productSubTotal)}</span></div>
										</div>
										{(parseFloat(productAddonsAmount)>0)?<div className="pkg_total_row">
											<div className="cc-addon-subtotal-lhs"><b>Add-ons</b></div>
											<div className="cc-addon-subtotal-rhs">: <span>{showPriceValue(productAddonsAmount)}</span></div>
										</div>:''}
										{(parseFloat(productSetupAmount)>0)?<div className="pkg_total_row">
											<div className="cc-addon-subtotal-lhs"><b>Setup</b></div>
											<div className="cc-addon-subtotal-rhs">: <span>{showPriceValue(productSetupAmount)}</span></div>
										</div>:''}
										<div className="pkg_total_row grand_total">
											<div className="cc-addon-subtotal-lhs"><b>Total</b></div>
											<div className="cc-addon-subtotal-rhs">: <span>{showPriceValue(productGrandTotal)}</span></div>
										</div>
								  </div>
							 </div>
						
						    	  
						</div>
					 </div>
					 
				</div>);
		} else {
			return (<div><div className="pkg_listsec bx_out"> Product Details is empty.</div></div>);
		}
	}
	
	updateProductPax_Edt(indFlg,type,selectpaxcount,minpaxcount) {
		/*minpaxcount = (parseInt(minpaxcount) > 0) ? parseInt(minpaxcount) : 1;
		selectpaxcount = parseInt(selectpaxcount);
		if(type === 'minus') {
			if(minpaxcount < selectpaxcount && selectpaxcount > 1) {
				selectpaxcount = selectpaxcount - 1;
			}
		} else {
			selectpaxcount = selectpaxcount + 1;
		}*/
		
		$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').html('');
		$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').hide();
		
		minpaxcount = (parseInt(minpaxcount) > 0) ? parseInt(minpaxcount) : 5;
		selectpaxcount = parseInt(selectpaxcount);
		if(type === 'minus') {
			var tempVl = (selectpaxcount > 5) ? (selectpaxcount - 5) : 0;
			if(minpaxcount <= tempVl && tempVl > 1) {
				selectpaxcount = selectpaxcount - 5;
			}
		} else {
			selectpaxcount = selectpaxcount + 5;
		}
		
		var indKey = 'Index_'+indFlg;
		var proFieldArr = Array();
		var stateProFldDet = this.state.productfeilsdetail_edit;
		if(Object.keys(stateProFldDet).length > 0) {
		   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
	    }
		proFieldArr['selectpaxcount'] = selectpaxcount;
		stateProFldDet[indKey] = proFieldArr;
		this.setState({productfeilsdetail_edit: stateProFldDet}, function () { this.calculatedOverAllAmount_Edt(indFlg); }.bind(this));
	}
	
	proMdfmainData_Edt(modifiersData) {
		var mdfName = (modifiersData.pro_modifier_alias_name) ? modifiersData.pro_modifier_alias_name : modifiersData.pro_modifier_name;
		return modifiersData.pro_modifier_id+'~~'+stripslashes(mdfName);
	}
	
	productModifiers_Edt(indFlg,proModifiers) {
		
		const Modifiershtml = proModifiers.map((Modifiers, index1) =>
								 <div className="col-md-6 col-sm-6 modifiers_main_list" id={"mdfEdt-"+indFlg+"-"+index1} data-mdfmin={Modifiers.pro_modifier_min_select} data-mdfmax={Modifiers.pro_modifier_max_select} data-mdfmain={this.proMdfmainData_Edt(Modifiers)} key={index1}>
										<div className="package_checkitem" style={{height: "100%"}} >
											<h3 className="package_formtt">
												<span>{(Modifiers.pro_modifier_alias_name) ? stripslashes(Modifiers.pro_modifier_alias_name) : stripslashes(Modifiers.pro_modifier_name)}</span>
													{(parseInt(Modifiers.pro_modifier_min_select) > 0)?<small>(Choice of {Modifiers.pro_modifier_min_select+" - "+Modifiers.pro_modifier_max_select} of the followings)</small>:""}
											</h3>														
											<ul className="package_formchecklist">
											{this.productModifierValues_Edt(indFlg,index1,Modifiers.modifiers_values)}
											</ul>
											<div className="pkgerror_txt" style={{display:'none'}}>Please select 1 more item!</div>
										</div>
								  </div>); 
			return Modifiershtml;	
		
	}
	
	productModifierValues_Edt(indFlg,index1,modifiersValues) {
		if(Object.keys(modifiersValues).length > 0) {
			const Modifiershtml = modifiersValues.map((ModfrValue, index2) =>
								  <li key={index2} className="modfr_valuecls" id={"mdfvalueEdt-"+indFlg+"-"+index1+"-"+index2} data-mdfsub={ModfrValue.pro_modifier_value_id+'~~'+stripslashes(ModfrValue.pro_modifier_value_name)+'~~1~~'+ModfrValue.pro_modifier_value_price}>
								    <div className="custom_checkbox">
										<input type="checkbox" className="modifiers_valuecheck" value={ModfrValue.pro_modifier_value_id} onChange={this.checkModfrValue_Edt.bind(this,indFlg,index1,index2)} checked={this.CheckModfrValues_Edt(indFlg,index1,index2)}/>
										<span>
										    <b>{jsUcfirstFun(stripslashes(ModfrValue.pro_modifier_value_name))}</b>
											{(parseFloat(ModfrValue.pro_modifier_value_price)>0)?' ( $ '+ModfrValue.pro_modifier_value_price+' )':''}
											{(ModfrValue.pro_modifier_value_short_description !== '' && ModfrValue.pro_modifier_value_short_description !== null) ? "(" + ModfrValue.pro_modifier_value_short_description + ")" : ''}
										</span>
									</div>
								  </li>); 
		    return Modifiershtml;						  
		} else{
			return (<div className="m_norecord"> </div>);
		}
	}
	
	CheckModfrValues_Edt(indFlg,index1,index2) {
		var productModifier = this.state.product_modifiers_edit;
		var selecteMdfMain = Array();
		var selecteMdfSub = Array();
		var mdfrValueArr = Array();
		if(Object.keys(productModifier).length > 0) {
			var indKey = 'Index_'+indFlg;
			selecteMdfMain = (!(indKey in productModifier)) ? Array() : productModifier[indKey];
			if(Object.keys(selecteMdfMain).length > 0) {
				var subKey = 'modifr_'+index1;
				selecteMdfSub = (!(subKey in selecteMdfMain)) ? Array() : selecteMdfMain[subKey];
				
				mdfrValueArr = (Object.keys(selecteMdfSub).length > 0) ? selecteMdfSub['modifiervalues'] : Array();
			}
		}
		
		var mdfrValue = '';
		var mdValKy = 'mdfval_'+index2;
		if(Object.keys(mdfrValueArr).length > 0) {
			mdfrValue = (!(mdValKy in mdfrValueArr)) ? '' : mdfrValueArr[mdValKy];
		}
		
		var chkBox = false;
		if(mdfrValue !== '') {
			chkBox = true;
		}
		
		return chkBox;
	}
	
	checkModfrValue_Edt(indFlg,index1,index2) {
		var proMin = $("#proDetMainEdt-"+indFlg).attr('data-packageminlimit');
		var proMax = $("#proDetMainEdt-"+indFlg).attr('data-packagemaxlimit');
		var modMin = $("#mdfEdt-"+indFlg+"-"+index1).attr('data-mdfmin');
		var modMax = $("#mdfEdt-"+indFlg+"-"+index1).attr('data-mdfmax');
		var checked_count = 0;
		var total_count = 0;
		var overallProPrice = 0;
		
		$("#mdfEdt-"+indFlg+"-"+index1).find('.modifiers_valuecheck').each(function(){
			if($(this).is(':checked')){
			  checked_count +=1;
			}
		});
		
		$("#proDetMainEdt-"+indFlg).find('.modifiers_valuecheck').each(function(){
			if($(this).is(':checked')){
			  total_count +=1;
			}
		});
		
		$("#mdfEdt-"+indFlg+"-"+index1).removeClass('pkgerror_out');
		$("#mdfEdt-"+indFlg+"-"+index1).find('.pkgerror_txt').hide();
		$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').hide();
		if(parseInt(modMin) > parseInt(checked_count)) {
			var errorTxt = "Please select minimum "+modMin+" item."
			$("#mdfEdt-"+indFlg+"-"+index1).find('.pkgerror_txt').html(errorTxt);
			$("#mdfEdt-"+indFlg+"-"+index1).addClass('pkgerror_out');
			$("#mdfEdt-"+indFlg+"-"+index1).find('.pkgerror_txt').show();
			/*$("#mdfvalueEdt-"+indFlg+"-"+index1+"-"+index2).find('.modifiers_valuecheck').prop('checked', true);*/
		} else if(parseInt(modMax) < parseInt(checked_count)) {
			var errorTxt = "Sorry!. maximum of selected item is "+modMax+" only."
			$("#mdfEdt-"+indFlg+"-"+index1).find('.pkgerror_txt').html(errorTxt);
			$("#mdfEdt-"+indFlg+"-"+index1).addClass('pkgerror_out');
			$("#mdfEdt-"+indFlg+"-"+index1).find('.pkgerror_txt').show();
			$("#mdfvalueEdt-"+indFlg+"-"+index1+"-"+index2).find('.modifiers_valuecheck').prop('checked', false);
		} else if(parseInt(proMax) < parseInt(total_count)) {
			var errorTxt = "Sorry!. maximum of selected item is "+proMax+" only."
			$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').html(errorTxt);
			$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').show();
			$("#mdfvalueEdt-"+indFlg+"-"+index1+"-"+index2).find('.modifiers_valuecheck').prop('checked', false);
		}
		
		var indKey = 'Index_'+indFlg;
		var subKey = 'modifr_'+index1;
		
		var productModfrPrice= this.state.product_modifiers_price_edit;
		$("#proDetMainEdt-"+indFlg).find('.modifiers_valuecheck').each(function(){
			if($(this).is(':checked')){
			  var modfrValueData = $(this).closest('.modfr_valuecls').attr('data-mdfsub');
			  var mdfvluArr = modfrValueData.split("~~");
			  overallProPrice += parseFloat(mdfvluArr[3]);
			}
		});
		productModfrPrice[indKey] = overallProPrice;
		
		var tempArr = Array();var allValEmpty = 'yes';
		var modfrMn = $("#mdfEdt-"+indFlg+"-"+index1).attr('data-mdfmain');
		$("#mdfEdt-"+indFlg+"-"+index1).find('.modifiers_valuecheck').each(function(mdint){
			if($(this).is(':checked')){
			  tempArr['mdfval_'+mdint] = $(this).closest('.modfr_valuecls').attr('data-mdfsub');
			  allValEmpty = 'No';
			}
		});
		
		
		var productModfr= this.state.product_modifiers_edit;
		if(allValEmpty == 'No') {
			var MdfArr = Array();
			MdfArr['modifiers'] = modfrMn;
			MdfArr['modifiervalues'] = tempArr;
			productModfr[indKey][subKey] = MdfArr;
		} else {
			productModfr[indKey][subKey] = Array();
		}
		
		this.setState({product_modifiers_edit: productModfr, product_modifiers_price_edit: productModfrPrice}, function () { this.calculatedOverAllAmount_Edt(indFlg); }.bind(this));
		
		this.hideModfrValueError_Edt(indFlg,index1);
	}
	
	hideModfrValueError_Edt(indFlg,index1)
	{
		setTimeout(function()
		{
			$("#mdfEdt-"+indFlg+"-"+index1).removeClass('pkgerror_out');
			$("#mdfEdt-"+indFlg+"-"+index1).find('.pkgerror_txt').hide();
			$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').hide();
		}, 6000);
	}
	
	productAddonsSetup_Edt(indFlg,addonsSetup) {
		    
			const addonshtml = addonsSetup.map((proAddons, index3) =>
								  <li key={index3} id={"addonsLiEdt-"+indFlg+"-"+index3} onChange={this.openAddonsPopup_Edt.bind(this,indFlg,index3,proAddons)} className={this.CheckAddonsValues_Edt(indFlg,index3,'class')} data-addonmin={proAddons.cat_addon_min_select} data-addonmax={proAddons.cat_addon_max_select} data-addontype={proAddons.cat_addon_selection}>
									<div className="custom_checkbox custom-addon-checkbox">
										<input type="checkbox" onChange={this.handlerTestFun_Edt.bind(this)} className="addonCheck" checked={this.CheckAddonsValues_Edt(indFlg,index3,'chkbox')}/>
										<span> {proAddons.cat_addon_title} </span>
										<div className="pkgerror_txt" style={{display:'none'}}>Please select 1 more item!</div>
									</div>
									<div id={"addonsDetEdt-"+indFlg+"-"+index3} className="white-popup mfp-hide popup_sec addon_popup">
									    
										<div className="pouup_in">
											<div className="addon_poptop">	
												<div className="addon_poptop_header">
														<h3>{proAddons.cat_addon_title}
														
														{(parseInt(proAddons.cat_addon_min_select) === 0 && parseInt(proAddons.cat_addon_max_select) === 0)?"":<small>(Select {proAddons.cat_addon_min_select+" - Max "+proAddons.cat_addon_max_select} selections)</small>}
														
														</h3>
												</div>
												
												<div className="addon_listsec">
												{(proAddons.cat_addon_selection === 'single')?this.singleAddonPopupList_Edt(indFlg,index3,proAddons):this.multipleAddonPopupList_Edt(indFlg,index3,proAddons)}
												</div>
												
											</div>
											<div className="addon_popbot">
												<div className="row-replace">
													<div className="col-md-cls">
														<a href="javascript:void(0)" className="button common-mfd-closefun" title="Go Back">Go Back</a>							
													</div>
													<div className="col-md-cls text-right">
														<a href="javascript:void(0)" className="button common-mfd-closefun" title="Continue">Continue</a>							
													</div>
												</div>
											</div>
										</div>
										
									</div>
								  </li>); 
			return addonshtml;						  
		
	}
	
	openAddonsPopup_Edt(indFlg,index3,DelryValue) {
		var productAddons = this.state.product_addons_edit;
		var selecteAddonsMain = Array();
		var selecteAddonSub = Array();
		if(Object.keys(productAddons).length > 0) {
			var indKey = 'Index_'+indFlg;
			selecteAddonsMain = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
			if(Object.keys(selecteAddonsMain).length > 0) {
				var subKey = 'addon_'+index3;
				selecteAddonSub = (!(subKey in selecteAddonsMain)) ? Array() : selecteAddonsMain[subKey];
			}
		}
		
		var liIdtxt = 'addonsLiEdt-'+indFlg+'-'+index3;
		$('#'+liIdtxt).removeClass('active');
		if(Object.keys(selecteAddonSub).length > 0) {
			$('#'+liIdtxt).addClass('active');
			$('#'+liIdtxt).find('.addonCheck').prop('checked', true);
		} else {
			$('#'+liIdtxt).find('.addonCheck').prop('checked', false);
			
			if(DelryValue.cat_addon_selection === 'single'){
				$('#addonsDetEdt-'+indFlg+'-'+index3).find('.addon_list_single').prop('checked', false);
			} else if(DelryValue.cat_addon_selection === 'multiple'){
				$('#addonsDetEdt-'+indFlg+'-'+index3).find('.addon_qty_input').val('0');
				$('#addonsDetEdt-'+indFlg+'-'+index3).find('.plue_muns_div').hide();
				$('#addonsDetEdt-'+indFlg+'-'+index3).find('.qty_count_sp, .delete-act-icon').hide();
				$('#addonsDetEdt-'+indFlg+'-'+index3).find('.add_tocart_div').show();
			}
		}
		
		var idText = '#addonsDetEdt-'+indFlg+'-'+index3;
		$.magnificPopup.open({
			items: {
				src: idText
			},
			type: 'inline'
		});
	}
	
	CheckAddonsValues_Edt(indFlg,index3,type) {
		var productAddons = this.state.product_addons_edit;
		var selecteAddonsMain = Array();
		var selecteAddonSub = Array();
		if(Object.keys(productAddons).length > 0) {
			var indKey = 'Index_'+indFlg;
			selecteAddonsMain = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
			if(Object.keys(selecteAddonsMain).length > 0) {
				var subKey = 'addon_'+index3;
				selecteAddonSub = (!(subKey in selecteAddonsMain)) ? Array() : selecteAddonsMain[subKey];
			}
		}
		
		var classTxt = '';
		var chkBox = false;
		if(Object.keys(selecteAddonSub).length > 0) {
			classTxt = 'active';
			chkBox = true;
		}
		
		return (type === 'class') ? classTxt : chkBox;
	}
	
	singleAddonPopupList_Edt(indFlg,index3,proAddons) {
		var proAddonsValues = proAddons.addons_setup_values;
		
		if(Object.keys(proAddonsValues).length > 0) {
			const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) =>
										<div className="addon_listrw addon_list_main" id={"addon_main_Edt-"+indFlg+"-"+index3+"-"+index6} key={index6}>
											<div className="addon_linfo">
											   <div className="custom_checkbox">
												<input type="checkbox" className="addon_list_single" onChange={this.addonSingleCart_Edt.bind(this,indFlg,index3,index6)} checked={this.CheckSnglAddonValues_Edit(indFlg,index3,index6,'chkbox','no')} />
												<span>
												<h4>{AddonsValue.cat_addonvalues_title}</h4>
													{(AddonsValue.cat_addonvalues_description !== '')?<p>{AddonsValue.cat_addonvalues_description}</p>:''}
												</span>
												</div>		
											</div>
											<div data-addonbind={proAddons.product_catering_addons_setup_id+"~~"+proAddons.cat_addon_title+"~~"+proAddons.cat_addon_selection+"~~"+AddonsValue.cat_addonvalues_id+"~~"+AddonsValue.cat_addonvalues_title+"~~"+AddonsValue.cat_addonvalues_description+"~~"+AddonsValue.cat_addonvalues_price} className="addon_lright text-right add_tocart_div">
											    <span className="cross_multi qty_count_sp" style={{display:'none'}}>x1</span>
												<span className="addon_price">{showPriceValue(AddonsValue.cat_addonvalues_price)}</span>
											</div>
										</div>); 
			 return AddonsValuehtml;
		}
	}
	
	CheckSnglAddonValues_Edit(indFlg,index3,index6,type,actiondiv) {
		
		var productAddons = this.state.product_addons_edit;
		var chkBox = false;
		var selecteAddonsMain = Array();
		var selecteAddonSub = Array();
		if(Object.keys(productAddons).length > 0) {
			var indKey = 'Index_'+indFlg;
			selecteAddonsMain = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
			if(Object.keys(selecteAddonsMain).length > 0) {
				var subKey = 'addon_'+index3;
				selecteAddonSub = (!(subKey in selecteAddonsMain)) ? Array() : selecteAddonsMain[subKey];
			}
		}
		
		var mainDivId = "addon_main_Edt-"+indFlg+"-"+index3+"-"+index6;
		var classNm = (type === 'text') ? 'plue_muns_div' : 'add_tocart_div';
		var alltmFl = $('#'+mainDivId).find('.'+classNm).attr('data-addonbind');
		var addonVlidtext = '';
		if (typeof alltmFl !== 'undefined') {
			var defltadnarr = alltmFl.split("~~");
			addonVlidtext = defltadnarr[3];
		}
		
		if(type === 'text') {
			var addonminpax = $('#'+mainDivId).find('.plue_muns_div').attr('data-addonminpax');
			var qtyIntTxt = (parseInt(addonminpax)>0)?'x'+addonminpax:'x1';
			var qtyTxt = '<span class="cross_multi qty_count_sp" style="display:none">'+qtyIntTxt+'</span>';
		} else {
			var qtyTxt = '<span class="cross_multi qty_count_sp" style="display:none">x1</span>';
		}
		
		
		var ramoveAct = 'none';
		if(Object.keys(selecteAddonSub).length > 0) {
			
			for(var key in selecteAddonSub) {
				var ExpVarbl = selecteAddonSub[key];
				var mytemparr = ExpVarbl.split("~~");
				if(mytemparr[3] === addonVlidtext) {
				  chkBox = true;
				  var stylTxt = (parseInt(mytemparr[7])>0) ? '' : 'style="display:none"';
				  qtyTxt = '<span class="cross_multi qty_count_sp" '+stylTxt+' >x'+mytemparr[7]+'</span>';
				  ramoveAct = (parseInt(mytemparr[7])>0) ? 'block' : 'none';
				}
			}
		}
		
		if(type === 'chkbox' && actiondiv === 'no') {
			return chkBox;
		} else if(type === 'text' && actiondiv === 'no') {
			var qtyTxtHtml = Parser(qtyTxt);
			return qtyTxtHtml;
		} else if(actiondiv === 'yes') {
			return ramoveAct;
		}
	}
	
	addonSingleCart_Edt(indFlg,index3,index6) {
		
		var mainDivId = "addon_main_Edt-"+indFlg+"-"+index3+"-"+index6;
		var indKey = 'Index_'+indFlg;
		var subKey = 'addon_'+index3;
		var liIdtxt = 'addonsLiEdt-'+indFlg+'-'+index3;
		
		$('#'+liIdtxt).find('.pkgerror_txt').hide();
		$('#'+mainDivId).closest('.addon_popup').find('.addon_poptop_header').find('small').removeClass('apply-red');
		var addonMin = $('#'+liIdtxt).attr('data-addonmin');
		var addonMax = $('#'+liIdtxt).attr('data-addonmax');
		var minmaxVld = 'Yes';
		if(parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
			minmaxVld = 'No';
		}
		
		var tempArr = Array();var allValEmpty = 'yes';
		var incvl = 0;
		$('#'+mainDivId).closest('.addon_popup').find('.addon_list_single').each(function(newInt){
			if($(this).is(':checked')){
				var alltmFl = $(this).closest('.addon_list_main').find('.add_tocart_div').attr('data-addonbind');
				var temarFl = alltmFl+"~~1";
				tempArr[incvl] = temarFl;
				allValEmpty = 'No';
				incvl++;
			}
		});
		
		if(minmaxVld === 'Yes') {
			
			if((parseInt(addonMax) < parseInt(incvl)) && (parseInt(incvl) > 0)) {
				var errorTxt = "Maximum of selected item is "+addonMax+" only.";
				$('#'+mainDivId).closest('.addon_popup').find('.addon_poptop_header').find('small').addClass('apply-red');
				$('#'+liIdtxt).find('.pkgerror_txt').html(errorTxt);
				$('#'+liIdtxt).find('.pkgerror_txt').show();
			}
			
			if((parseInt(addonMin) > parseInt(incvl)) && (parseInt(incvl) > 0)) {
				var errorTxt = "Please select minimum "+addonMin+" item.";
				$('#'+mainDivId).closest('.addon_popup').find('.addon_poptop_header').find('small').addClass('apply-red');
				$('#'+liIdtxt).find('.pkgerror_txt').html(errorTxt);
				$('#'+liIdtxt).find('.pkgerror_txt').show();
			}
			
		}
		
		var productAddons = this.state.product_addons_edit;
		if(allValEmpty == 'No') {
			productAddons[indKey][subKey] = tempArr;
		} else {
			productAddons[indKey][subKey] = Array();
		}
		
		this.setState({product_addons_edit: productAddons}, function () { this.calculatedOverAllAmount_Edt(indFlg); }.bind(this));
		
	}
	
	multipleAddonPopupList_Edt(indFlg,index3,proAddons) {
		var proAddonsValues = proAddons.addons_setup_values;
		
		if(Object.keys(proAddonsValues).length > 0) {
			const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) =>
										<div className="addon_listrw addon_list_main" id={"addon_main_Edt-"+indFlg+"-"+index3+"-"+index6} key={index6}>
											<div className="addon_linfo">
												<h4>{AddonsValue.cat_addonvalues_title}</h4>
													{(AddonsValue.cat_addonvalues_description !== '')?<p>{AddonsValue.cat_addonvalues_description}</p>:''}
											</div>
											<div className="addon_lright text-right add_tocart_div">
												{this.CheckSnglAddonValues_Edit(indFlg,index3,index6,'text','no')}
												<div className="act-remove-topdiv">
												<span className="addon_price">{showPriceValue(AddonsValue.cat_addonvalues_price)}</span>
												<a href="javascript:void(0)" onClick={this.addonPlusMinusCart_Edt.bind(this,indFlg,index3,index6,'remove',AddonsValue.cat_addonvalues_min_pax)} className="delete-act-icon" title="Remove" style={{display:this.CheckSnglAddonValues_Edit(indFlg,index3,index6,'text','yes')}}><i className="fa fa-trash-o" data-unicode="f014"></i></a>
												</div>
												<a href="javascript:void(0)" onClick={this.addonSubAddtoCart_Edt.bind(this,indFlg,index3,index6)} className="button" title="Add to cart">Add to cart</a>							
											</div>
										    <div data-addonbind={proAddons.product_catering_addons_setup_id+"~~"+proAddons.cat_addon_title+"~~"+proAddons.cat_addon_selection+"~~"+AddonsValue.cat_addonvalues_id+"~~"+AddonsValue.cat_addonvalues_title+"~~"+AddonsValue.cat_addonvalues_description+"~~"+AddonsValue.cat_addonvalues_price} className="addon_lright text-right plue_muns_div" data-addonminpax={AddonsValue.cat_addonvalues_min_pax} style={{display:'none'}}>
												<span className="cross_multi qty_count_sp" style={{display:'none'}}>{(parseInt(AddonsValue.cat_addonvalues_min_pax)>0)?'x'+AddonsValue.cat_addonvalues_min_pax:'x1'}</span>	
												<span className="addon_price">{showPriceValue(AddonsValue.cat_addonvalues_price)}</span>
												<div className="qty_bx catqty_bx"> 
													<span className="qty_minus" onClick={this.addonPlusMinusCart_Edt.bind(this,indFlg,index3,index6,'minus',AddonsValue.cat_addonvalues_min_pax)}>--</span>
													<input type="text" className="addon_qty_input" value={this.getQtyValue_Edt(indFlg,index3,index6,AddonsValue.cat_addonvalues_min_pax)} readOnly /> 
													<span className="qty_plus" onClick={this.addonPlusMinusCart_Edt.bind(this,indFlg,index3,index6,'plus',AddonsValue.cat_addonvalues_min_pax)}>+</span> 
												</div>
												<a href="javascript:void(0)" onClick={this.addonDoneCart_Edt.bind(this,indFlg,index3,index6)} className="button" title="Add to cart">Done</a>							
											</div>
										</div>); 
			 return AddonsValuehtml;
		}
	}
	
	addonSubAddtoCart_Edt(indFlg,index3,index6) {
		var mainDivId = "addon_main_Edt-"+indFlg+"-"+index3+"-"+index6;
		$('#'+mainDivId).find('.add_tocart_div').hide();
		$('#'+mainDivId).find('.plue_muns_div').show();
	}
	
	addonPlusMinusCart_Edt(indFlg,index3,index6,type,minPax) {
		var mainDivId = "addon_main_Edt-"+indFlg+"-"+index3+"-"+index6;
		var qty_input = $('#'+mainDivId).find('.addon_qty_input').val();
		var minPaxVal = (minPax !== '')?parseInt(minPax):0;
		if(type === 'remove') {
			$('#'+mainDivId).find('.addon_qty_input').val(0);
			this.addonDoneCart_Edt(indFlg,index3,index6);
		} else if(type === 'minus') {
			qty_input = parseInt(qty_input);
			if(qty_input <= minPaxVal) {
				$('#'+mainDivId).find('.addon_qty_input').val(0);
				this.addonDoneCart_Edt(indFlg,index3,index6);
			} else if(qty_input > 0) {
				qty_input = qty_input - 1;
				$('#'+mainDivId).find('.addon_qty_input').val(qty_input);
			}
			
		} else {
			qty_input = (parseInt(qty_input) < minPaxVal) ? minPaxVal : parseInt(qty_input) + 1;
			$('#'+mainDivId).find('.addon_qty_input').val(qty_input);
		}
		var count_sp_text = 'X'+qty_input;
		$('#'+mainDivId).find('.add_tocart_div').find('.qty_count_sp').html(count_sp_text);
	}
	
	getQtyValue_Edt(indFlg,index3,index6,minPax) {
		var productAddons = this.state.product_addons_edit;
		var selecteAddonsMain = Array();
		var selecteAddonSub = Array();
		/*var qtyValue = (minPax !== '') ? parseInt(minPax) : 0;*/
		var qtyValue = 0;
		if(Object.keys(productAddons).length > 0) {
			var indKey = 'Index_'+indFlg;
			selecteAddonsMain = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
			if(Object.keys(selecteAddonsMain).length > 0) {
				var subKey = 'addon_'+index3;
				selecteAddonSub = (!(subKey in selecteAddonsMain)) ? Array() : selecteAddonsMain[subKey];
			}
		}
		
		if(Object.keys(selecteAddonSub).length > 0) {
			var ExpVarbl = selecteAddonSub[index6];
			var mytemparr = ExpVarbl.split("~~");
			qtyValue = mytemparr[7];
		}
		
		return qtyValue;
	}
	
	addonDoneCart_Edt(indFlg,index3,index6) {
		
		var mainDivId = "addon_main_Edt-"+indFlg+"-"+index3+"-"+index6;
		var indKey = 'Index_'+indFlg;
		var subKey = 'addon_'+index3;
		
		var liIdtxt = 'addonsLiEdt-'+indFlg+'-'+index3;
		
		$('#'+liIdtxt).find('.pkgerror_txt').hide();
		$('#'+mainDivId).closest('.addon_popup').find('.addon_poptop_header').find('small').removeClass('apply-red');
		var addonMin = $('#'+liIdtxt).attr('data-addonmin');
		var addonMax = $('#'+liIdtxt).attr('data-addonmax');
		var minmaxVld = 'Yes';
		if(parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
			minmaxVld = 'No';
		}
		
		var tempArr = Array();var allValEmpty = 'yes';
		var itmCount = 0;
		$('#'+mainDivId).closest('.addon_popup').find('.addon_qty_input').each(function(newInt){
			var alltmFl = $(this).closest('.plue_muns_div').attr('data-addonbind');
			var qtyInt = $(this).val();
			var temarFl = alltmFl+"~~"+qtyInt;
			tempArr[newInt] = temarFl;
			if(parseInt(qtyInt) > 0) {
				allValEmpty = 'No';
				itmCount++;
			}
		});
		
		if(minmaxVld === 'Yes') {
			
			if((parseInt(addonMax) < parseInt(itmCount)) && (parseInt(itmCount) > 0)) {
				var errorTxt = "Maximum of selected item is "+addonMax+" only.";
				$('#'+mainDivId).closest('.addon_popup').find('.addon_poptop_header').find('small').addClass('apply-red');
				$('#'+liIdtxt).find('.pkgerror_txt').html(errorTxt);
				$('#'+liIdtxt).find('.pkgerror_txt').show();
			}
			
			if((parseInt(addonMin) > parseInt(itmCount)) && (parseInt(itmCount) > 0)) {
				var errorTxt = "Please select minimum "+addonMin+" item.";
				$('#'+mainDivId).closest('.addon_popup').find('.addon_poptop_header').find('small').addClass('apply-red');
				$('#'+liIdtxt).find('.pkgerror_txt').html(errorTxt);
				$('#'+liIdtxt).find('.pkgerror_txt').show();
			}
			
		}
		
		var productAddons = this.state.product_addons_edit;
		if(allValEmpty == 'No') {
			productAddons[indKey][subKey] = tempArr;
		} else {
			productAddons[indKey][subKey] = Array();
		}
		
		this.setState({product_addons_edit: productAddons}, function () { this.calculatedOverAllAmount_Edt(indFlg); }.bind(this));
		
		var qty_input = $('#'+mainDivId).find('.addon_qty_input').val();
		
		$('#'+mainDivId).find('.plue_muns_div').hide();
		if(parseInt(qty_input) > 0) {
			$('#'+mainDivId).find('.add_tocart_div').find('.qty_count_sp, .delete-act-icon').show();
		} else {
			$('#'+mainDivId).find('.add_tocart_div').find('.qty_count_sp, .delete-act-icon').hide();
		}
		$('#'+mainDivId).find('.add_tocart_div').show();
		
	}
	
	productDeliverySetupHtml_Edt(indFlg,DeliverySetup) {
		if(Object.keys(DeliverySetup).length > 0) {
			return (<div className="pkgsetup_listrw delivery_main_div">
						<div className="package_controltt">
						    <div className="delivery_setup_error" style={{color:'red',fontWeight:'bold',display:'none'}}>Please select your delivery setup</div>
							<div className="custom_radio">
								<input type="radio" className="setup_cls delivery_input" onChange={this.handleClickSetup_Edt.bind(this,indFlg,'delivery')} checked={this.CheckSetup_Edt(indFlg,'Delivery','chkbox')} name="setup_input" value="delivery"/>
								<span>Delivery</span>
							</div>
						</div>
						<ul className="package_formchecklist delivery_listing_div" style={{display:this.CheckSetup_Edt(indFlg,'Delivery','div')}}>
							{this.productDeliverySetup_Edt(indFlg,DeliverySetup)}
						</ul>
					</div>);					  
		}
	}
	
	handleClickSetup_Edt(indFlg,type) {
		
		var indKey = 'Index_'+indFlg;
		var subKey1 = 'delivery';
		var subKey2 = 'buffet';
		var deliveryArr = Array();
		var buffetArr = Array();
		var sateProductSetup = this.state.product_setup_edit;
		$('#setupEdt_'+indFlg).find('.delivery_setup_error').hide();
		$('#setupEdt_'+indFlg).find('.buffet_setup_error').hide();
		
		if(type == 'delivery') {
			var cartSetupflg = 'Delivery';
			$('#setupEdt_'+indFlg).find('.buffet_listing_div').hide();
			$('#setupEdt_'+indFlg).find('.delivery_listing_div').show();
			sateProductSetup[indKey][subKey2] = buffetArr;
			
			$('#setupEdt_'+indFlg).find('.buffet_listing_div').find('.setup_listing_value').each(function(dlvy){
				$(this).prop('checked', false);
			});
			
		} else {
			var cartSetupflg = 'Buffet';
			$('#setupEdt_'+indFlg).find('.delivery_listing_div').hide();
			$('#setupEdt_'+indFlg).find('.buffet_listing_div').show();
			sateProductSetup[indKey][subKey1] = deliveryArr;
			
			$('#setupEdt_'+indFlg).find('.delivery_listing_div').find('.setup_listing_value').each(function(dlvy){
				$(this).prop('checked', false);
			});
		}
		
		this.setState({product_setup_edit: sateProductSetup,cart_setupflg: cartSetupflg}, function () { this.calculatedOverAllAmount_Edt(indFlg); }.bind(this));
	}
	
	CheckSetup_Edt(indFlg,type,inptype) {
		var cartSetupflg = this.state.cart_setupflg;
		var chkBox = false;
		var displaytxt = 'none';
		if(type === cartSetupflg) {
			chkBox = true;
			displaytxt = 'block';
		}
		
		if(inptype === 'chkbox') {
			return chkBox;
		} else if(inptype === 'div') {
			return displaytxt;
		}
	}
	
	productDeliverySetup_Edt(indFlg,DeliverySetup) {
			const Deliveryhtml = DeliverySetup.map((DelryValue, index4) =>
								  <li key={index4} className="setup_li" data-setupdet={DelryValue.catering_delivery_setup_id+"~~"+DelryValue.catering_delivery_title+"~~"+DelryValue.catering_delivery_description+"~~"+DelryValue.catering_delivery_price+"~~1~~Delivery"}>
								    <div className="custom_checkbox">
										<input type="checkbox" className="setup_listing_value" value={DelryValue.catering_delivery_price} onChange={this.handleUpdateSetup_Edt.bind(this,indFlg,index4,'delivery')} checked={this.CheckDeliveryBuff_Edt(indFlg,index4,DelryValue.catering_delivery_setup_id,'delivery')} />
										<span>
										    {DelryValue.catering_delivery_title+" ( "+showPriceValue(DelryValue.catering_delivery_price)+" ) : "+DelryValue.catering_delivery_description}
										</span>
									</div>
								  </li>); 
		    return Deliveryhtml;						  
	}
	
	handleUpdateSetup_Edt(indFlg,indexInr,type) {
		
		var deliveryArr = Array();
		var buffetArr = Array();
		$('#setupEdt_'+indFlg).find('.delivery_setup_error').hide();
		$('#setupEdt_'+indFlg).find('.buffet_setup_error').hide();
		if(type === 'delivery') {
			var incvl = 0;
			$('#setupEdt_'+indFlg).find('.delivery_listing_div').find('.setup_listing_value').each(function(dlvy){
				if($(this).is(':checked')){
					deliveryArr[incvl] = $(this).closest('.setup_li').attr('data-setupdet');
					incvl++;
				}
			});
		} else if(type === 'buffet') {
			var incvl = 0;
			$('#setupEdt_'+indFlg).find('.buffet_listing_div').find('.setup_listing_value').each(function(dlvy){
				if($(this).is(':checked')){
					buffetArr[incvl] = $(this).closest('.setup_li').attr('data-setupdet');
					incvl++;
				}
			});
		}
		
		var indKey = 'Index_'+indFlg;
		var subKey1 = 'delivery';
		var subKey2 = 'buffet';
		var sateProductSetup = this.state.product_setup_edit;
			sateProductSetup[indKey][subKey1] = deliveryArr;
			sateProductSetup[indKey][subKey2] = buffetArr;
		
		this.setState({product_setup_edit: sateProductSetup}, function () { this.calculatedOverAllAmount_Edt(indFlg); }.bind(this));
	}
	
	CheckDeliveryBuff_Edt(indFlg,index4,idtxt,type) {
		
		var sateProductSetup = this.state.product_setup_edit;
		var selecteDBMain = Array();
		var selecteDBfSub = Array();
		var indKey = 'Index_'+indFlg;
		var subKey = type;
		var chkBox = false;
		
		if(Object.keys(sateProductSetup).length > 0) {
			selecteDBMain = (!(indKey in sateProductSetup)) ? Array() : sateProductSetup[indKey];
			if(Object.keys(selecteDBMain).length > 0) {
				selecteDBfSub = (!(subKey in selecteDBMain)) ? Array() : selecteDBMain[subKey];
				for(var key in selecteDBfSub) {
					var ExpVarbl = selecteDBfSub[key];
					var mytemparr = ExpVarbl.split("~~");
					if(mytemparr[0] == idtxt) {
					  chkBox = true;
					}
				}
			}
		}
		
		return chkBox;
	}
	
	productBuffetSetupHtml_Edt(indFlg,BuffetSetup) {
		if(Object.keys(BuffetSetup).length > 0) {
			return (<div className="pkgsetup_listrw buffet_main_div">
						<div className="package_controltt">
						    <div className="buffet_setup_error" style={{color:'red',fontWeight:'bold',display:'none'}}>Please select your buffet setup</div>
							<div className="custom_radio">
								<input type="radio" className="setup_cls buffet_input" onChange={this.handleClickSetup_Edt.bind(this,indFlg,'buffet')} checked={this.CheckSetup_Edt(indFlg,'Buffet','chkbox')} name="setup_input" value="delivery"/>
								<span>Buffets Setup</span>
							</div>
						</div>
						<ul className="package_formchecklist buffet_listing_div" style={{display:this.CheckSetup_Edt(indFlg,'Buffet','div')}}>
							{this.productBuffetSetup_Edt(indFlg,BuffetSetup)}
						</ul>
					</div>);					  
		}
	}
	
	productBuffetSetup_Edt(indFlg,BuffetSetup) {
			const Buffethtml = BuffetSetup.map((BuffetValue, index5) =>
								  <li key={index5} className="setup_li" data-setupdet={BuffetValue.catering_buffet_setup_id+"~~"+BuffetValue.catering_buffet_title+"~~"+BuffetValue.catering_buffet_description+"~~"+BuffetValue.catering_buffet_price+"~~1~~Buffet"}>
								    <div className="custom_checkbox">
										<input type="checkbox" className="setup_listing_value" value={BuffetValue.catering_buffet_price} checked={this.CheckDeliveryBuff_Edt(indFlg,index5,BuffetValue.catering_buffet_setup_id,'buffet')} onChange={this.handleUpdateSetup_Edt.bind(this,indFlg,index5,'buffet')} />
										<span>
										    {BuffetValue.catering_buffet_title+" ( "+showPriceValue(BuffetValue.catering_buffet_price)+" ) : "+BuffetValue.catering_buffet_description}
										</span>
									</div>
								  </li>); 
		    return Buffethtml;						  
	}
	
	handleSpecialInstruction_Edt(indFlg, event) {
		
		var stateProFldDet = this.state.productfeilsdetail_edit;
		var proFieldArr = Array();
		var indKey = 'Index_'+indFlg;
		if(Object.keys(stateProFldDet).length > 0) {
		   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
	    }
		var remarks = event.target.value;
		proFieldArr['productSpecialNotes'] = remarks;
		stateProFldDet[indKey] = proFieldArr;
		this.setState({productfeilsdetail_edit: stateProFldDet});
	}
	
	handlerTestFun_Edt() {
		
	}
	
	handlerTestFun1(indx) {
		$.magnificPopup.open({
						  items: {
							src: '.alert_popup_test'
						  },
						  type: 'inline'
						});
	}
	
	updateCartPackage(indFlg) {
		
		var indKey = 'Index_'+indFlg;
		var errorFlg = 'No';
		
		$("#proDetMainEdt-"+indFlg).find('.modifiers_main_list').each(function(){
			var checked_count = 0;
			var modMin = $(this).attr('data-mdfmin');
			var modMax = $(this).attr('data-mdfmax');
			$(this).find('.modifiers_valuecheck').each(function(){
				if($(this).is(':checked')){
				  checked_count +=1;
				}
			});
			
			if(parseInt(modMin) > parseInt(checked_count)) {
				errorFlg = 'Yes';
				var errorTxt = "Please select minimum "+modMin+" item."
				$(this).find('.pkgerror_txt').html(errorTxt);
				$(this).addClass('pkgerror_out');
				$(this).find('.pkgerror_txt').show();
				window.scrollTo(0, 500);
			    return false;
			}
			
			if(parseInt(modMax) < parseInt(checked_count)) {
				errorFlg = 'Yes';
				var errorTxt = "Sorry!. maximum of selected item is "+modMax+" only."
				$(this).find('.pkgerror_txt').html(errorTxt);
				$(this).addClass('pkgerror_out');
				$(this).find('.pkgerror_txt').show();
				window.scrollTo(0, 500);
			    return false;
			}
		});
		
		
		var proMin = $("#proDetMainEdt-"+indFlg).attr('data-packageminlimit');
		var proMax = $("#proDetMainEdt-"+indFlg).attr('data-packagemaxlimit');
		var total_count = 0;
		
		$("#proDetMainEdt-"+indFlg).find('.modifiers_valuecheck').each(function(){
			if($(this).is(':checked')){
			  total_count +=1;
			}
		});
		
		if(parseInt(proMax) < parseInt(total_count)) {
			errorFlg = 'Yes';
			var errorTxt = "Sorry!. maximum of selected item is "+proMax+" only."
			$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').html(errorTxt);
			$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').show();
			window.scrollTo(0, 500);
			return false;
		}
		
		if(parseInt(proMin) > parseInt(total_count)) {
			errorFlg = 'Yes';
			var errorTxt = "Please select minimum "+proMax+" item."
			$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').html(errorTxt);
			$("#proDetMainEdt-"+indFlg).find('.mdf_common_error').show();
			window.scrollTo(0, 500);
			return false;
		}
		
		var addonHaveError = 'No';
		var productAddonsChk = this.state.product_addons_edit;
		var selecteAdfMainChk = Array();
		if(Object.keys(productAddonsChk).length > 0) {
			selecteAdfMainChk = (!(indKey in productAddonsChk)) ? Array() : productAddonsChk[indKey];
		}
		$("#proDetMainEdt-"+indFlg).find('.pkgaddon_checklist').find('li').each(function(){
			$(this).find('.pkgerror_txt').hide();
			var addontype = $(this).attr('data-addontype');
			var addonMin = $(this).attr('data-addonmin');
			var addonMax = $(this).attr('data-addonmax');
			var minmaxVld = 'Yes';
			if(parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
				minmaxVld = 'No';
			}
			
			if(minmaxVld === 'Yes') {
				var getIndx = $(this).attr('id');
				var idTxtArr = getIndx.split("-");
				var mainDivIdTxt = "addonsDetEdt-"+idTxtArr[1]+"-"+idTxtArr[2];
				var intvCnt = 0;
				
				if(addontype === 'single') {
					$('#'+mainDivIdTxt).find('.addon_list_single').each(function(newInt){
							if($(this).is(':checked')){
								intvCnt++;
							}
					});
				} else if(addontype === 'multiple') {
					if(Object.keys(selecteAdfMainChk).length > 0) {
						var subKey = 'addon_'+idTxtArr[2];
						var AddonsArr = (!(subKey in selecteAdfMainChk)) ? Array() : selecteAdfMainChk[subKey];
						if(Object.keys(AddonsArr).length > 0) {
							
							for(var key1 in AddonsArr) {
								var ExpVarbl = AddonsArr[key1];
								var mytemparr = ExpVarbl.split("~~");
								if(parseInt(mytemparr[7]) > 0) {
									intvCnt++;
								}
							}
							
						}
					}
				}
		
				if((parseInt(addonMax) < parseInt(intvCnt)) && (parseInt(intvCnt) > 0)) {
					errorFlg = 'Yes'; addonHaveError = 'Yes';
					var errorTxt = "Maximum of selected item is "+addonMax+" only.";
					$(this).find('.pkgerror_txt').html(errorTxt);
					$(this).find('.pkgerror_txt').show();
					$('html, body').animate({
						scrollTop: $("#"+getIndx).offset().top
					}, 1000);
					return false;
				}
				
				if((parseInt(addonMin) > parseInt(intvCnt)) && (parseInt(intvCnt) > 0)) {
					errorFlg = 'Yes'; addonHaveError = 'Yes';
					var errorTxt = "Please select minimum "+addonMin+" item.";
					$(this).find('.pkgerror_txt').html(errorTxt);
					$(this).find('.pkgerror_txt').show();
					$('html, body').animate({
						scrollTop: $("#"+getIndx).offset().top
					}, 1000);
					return false;
				}
				
			}
			
		});
		if(addonHaveError === 'Yes') {
			return false;
		}
		
		if($('#setupEdt_'+indFlg).find('.delivery_input').is(':checked')) {
			var incvl = 0;
			$('#setupEdt_'+indFlg).find('.delivery_listing_div').find('.setup_listing_value').each(function(dlvy){
				if($(this).is(':checked')){
					incvl++;
				}
			});
			
			if(incvl === 0) {
				errorFlg = 'Yes';
				$("#setupEdt_"+indFlg).find('.delivery_setup_error').show();
				$('html, body').animate({
					scrollTop: $("#pkg_setupbx_"+indFlg).offset().top
				}, 1000);
				return false;
			}
		}
		
		if($('#setupEdt_'+indFlg).find('.buffet_input').is(':checked')) {
			var incvl = 0;
			$('#setupEdt_'+indFlg).find('.buffet_listing_div').find('.setup_listing_value').each(function(dlvy){
				if($(this).is(':checked')){
					incvl++;
				}
			});
			
			if(incvl === 0) {
				errorFlg = 'Yes';
				$("#setupEdt_"+indFlg).find('.buffet_setup_error').show();
				$('html, body').animate({
					scrollTop: $("#pkg_setupbx_"+indFlg).offset().top
				}, 1000);
				return false;
			}
		}
		
		var proFieldArr = Array(); var product_id = ''; var cartItemId = '';
		var stateProFldDet = this.state.productfeilsdetail_edit;
		if(Object.keys(stateProFldDet).length > 0) {
		   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
		   product_id = (!('product_id' in proFieldArr)) ? '' : proFieldArr['product_id'];
		   cartItemId = (!('cartItemId' in proFieldArr)) ? '' : proFieldArr['cartItemId'];
		   if(product_id === '' || cartItemId === '') {
			   errorFlg = 'Yes';
			   $('#tab-pks-edit').find('.alert_danger').show();
			   $('#tab-pks-edit').find('.alert_danger').delay(1500).fadeOut();
			   return false;
		   }
		   
		   var minPaxAmt = (!('minPaxAmount' in proFieldArr)) ? 0 : proFieldArr['minPaxAmount'];
		   var productSubTotal = (!('productSubTotal' in proFieldArr)) ? 0 : proFieldArr['productSubTotal'];
		   
		   if(parseFloat(minPaxAmt) > parseFloat(productSubTotal)) {
			   var errorTxt = "Package amount should be minimum $"+minPaxAmt+", so please increase your Pax";
			   $("#proDetMainEdt-"+indFlg).find('.mdf_common_error').html(errorTxt);
			   $("#proDetMainEdt-"+indFlg).find('.mdf_common_error').show();
			   $('html, body').animate({
					scrollTop: $("#proDetMainEdt-"+indFlg).offset().top
			   }, 1000);
			   return false;
		   }
	    }
		
		var postModifiers = [];
		var productModifier= this.state.product_modifiers_edit;
		if(Object.keys(productModifier).length > 0) {
			var selecteMdfMain = (!(indKey in productModifier)) ? Array() : productModifier[indKey];
			if(Object.keys(selecteMdfMain).length > 0) {
				for(var key in selecteMdfMain) {
					if(Object.keys(selecteMdfMain[key]).length > 0) {
					var Modifiers = selecteMdfMain[key].modifiers;
					var mdfArr = Modifiers.split("~~");
					
					var ModifiersValArr = [];
					var ModifiersValues = selecteMdfMain[key].modifiervalues;
					for(var key1 in ModifiersValues) {
						var ExpVarbl = ModifiersValues[key1];
						var mytemparr = ExpVarbl.split("~~");
						ModifiersValArr.push( { modifier_value_name:mytemparr[1], modifier_value_id:mytemparr[0], modifier_value_price:mytemparr[3], modifier_value_qty:mytemparr[2] } )
					}
					
					postModifiers.push( { modifier_name:mdfArr[1], modifier_id:mdfArr[0], modifiers_values:ModifiersValArr } )
					}
				}
			}
		}
		
		var postAddons = [];
		var productAddons = this.state.product_addons_edit;
		if(Object.keys(productAddons).length > 0) {
			var selecteAdfMain = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
			if(Object.keys(selecteAdfMain).length > 0) {
				for(var key in selecteAdfMain) {
					var Addons = selecteAdfMain[key];
					var AddonsValArr = [];
					var addon_setup_id = '';
					var addon_setup_title = '';
					var addon_setup_selection = '';
					for(var key1 in Addons) {
						var ExpVarbl = Addons[key1];
						var mytemparr = ExpVarbl.split("~~");
						
						addon_setup_id = mytemparr[0];
						addon_setup_title = mytemparr[1];
						addon_setup_selection = mytemparr[2];
						
						if(parseInt(mytemparr[7]) > 0) {
							AddonsValArr.push( { addon_setup_val_id:mytemparr[3], addon_setup_val_title:mytemparr[4], addon_setup_val_desc:mytemparr[5], addon_setup_val_price:mytemparr[6], addon_setup_val_qty:mytemparr[7] } )
						}
					}
					
					if(Object.keys(AddonsValArr).length > 0) {
						postAddons.push( { addon_setup_id:addon_setup_id, addon_setup_title:addon_setup_title, addon_setup_selection:addon_setup_selection, addons_setup_values:AddonsValArr } )
					}
				}
			}
		}
		
		
		var postSetup = [];
		var productSetup = this.state.product_setup_edit;
		if(Object.keys(productSetup).length > 0) {
			var selecteStMain = (!(indKey in productSetup)) ? Array() : productSetup[indKey];
			if(Object.keys(selecteStMain).length > 0) {
				
				var deliveryList = selecteStMain.delivery;
				if(Object.keys(deliveryList).length > 0) {
					for(var key in deliveryList) {
						
						var ExpVarbl = deliveryList[key];
						var mytemparr = ExpVarbl.split("~~");

						postSetup.push( { setupid:mytemparr[0], setupname:mytemparr[1], setupdescription:mytemparr[2], setupprice:mytemparr[3], setupqty:mytemparr[4], setuptype:mytemparr[5] } )
					}
				}
				
				var buffetList = selecteStMain.buffet;
				if(Object.keys(buffetList).length > 0) {
					for(var key in buffetList) {
						
						var ExpVarbl = buffetList[key];
						var mytemparr = ExpVarbl.split("~~");

						postSetup.push( { setupid:mytemparr[0], setupname:mytemparr[1], setupdescription:mytemparr[2], setupprice:mytemparr[3], setupqty:mytemparr[4], setuptype:mytemparr[5] } )
					}
				}
				
			}
		}
		
		if((errorFlg === 'No') && (Object.keys(proFieldArr).length > 0)) {
			
			showLoader('pgk-continue-div','class');
			/*$('.editDivLoadingCls').show();*/
			
			var brktimeSts = proFieldArr['brktimeSts'];
			var brktimeCnt = proFieldArr['brktimeCnt'];
			var brktimeLst = proFieldArr['brktimeLst'];
			var brktimeFlg = indFlg;
			var brktimeStr = proFieldArr['brktimeStr'];
			var brktimeEnd = proFieldArr['brktimeEnd'];
			
			var postHall = [];
			if(cookie.load('catering_eventType') === "hall") {
				postHall.push( { catering_venue_type:'catering_hall', catering_hall_id:cookie.load('cateringHallId'), hall_charges:cookie.load('cateringHallCharge') } )
			}
			
			var productSpecialNotes = (!('productSpecialNotes' in proFieldArr)) ? '' : proFieldArr['productSpecialNotes'];
			
			var postObject = {};
				postObject = {
					'app_id' 				: appId,
					'availability_id' 		: cateringId,
					'product_id' 			: proFieldArr['product_id'],
					'product_min_qty' 		: proFieldArr['minpaxcount'],
					'product_qty' 			: proFieldArr['selectpaxcount'],
					'product_name' 			: proFieldArr['product_name'],
					'product_sku' 			: proFieldArr['product_sku'],
					'product_slug' 			: proFieldArr['product_slug'],
					'product_unit_price' 	: proFieldArr['productPrice'],
					'product_total_price' 	: proFieldArr['productSubTotal'],
					'product_image' 		: proFieldArr['productImage'],
					'product_special_notes' : productSpecialNotes,
					'modifiers' 			: JSON.stringify(postModifiers),
					'addons_setup' 			: JSON.stringify(postAddons),
					'cateringhall' 			: JSON.stringify(postHall),
					'setup' 				: JSON.stringify(postSetup),
					'breaktime_enable' 		: brktimeSts,
					'breaktime_count' 		: brktimeCnt,
					'breaktime_list' 		: brktimeLst,
					'breaktime_indexflag' 	: brktimeFlg,
					'breaktime_started' 	: brktimeStr,
					'breaktime_ended' 		: brktimeEnd,
				}
			
			if (typeof cookie.load('UserId') === 'undefined') {
				postObject['reference_id'] = getReferenceID();
			} else {
				postObject['customer_id'] = cookie.load('UserId');
			}
			
			if(cartItemId !== '') {
				this.removeCartItem(cartItemId);
			}
			
			axios.post(apiUrlV2 + "cateringcart/insert", qs.stringify(postObject))
            .then(res => {
				/*$('.editDivLoadingCls').fadeOut(500);*/
				hideLoader('pgk-continue-div','class');
				if (res.data.status === "ok") {
					showCustomAlert('success','Nice! Products updated to your cart');
				} else {
					showCustomAlert('error','Sorry! Products can`t update your cart');
				}
				this.props.chkoutSateVal('removePromo', '', '');
				this.props.prpActiveTab(4);
				$("#PackageEditMdl").modal("hide");
			})
            .catch(function (error) {
                console.log(error);
				showCustomAlert('error','Sorry! Products can`t update your cart');
				/*$('.editDivLoadingCls').fadeOut(500);*/
				hideLoader('pgk-continue-div','class');
				this.props.prpActiveTab(4);
				$("#PackageEditMdl").modal("hide");
            });
			
		} else {
			$('#tab-pks-edit').find('.alert_danger').show();
		    $('#tab-pks-edit').find('.alert_danger').delay(1500).fadeOut();
		    return false;
		}
	}
	
	removeCartItem(cartItemId) {
			var updateObject = {};
				updateObject = {
					'app_id' 				: appId,
					'cart_item_id' 			: cartItemId,
				}
			if (typeof cookie.load('UserId') === 'undefined') {
				updateObject['reference_id'] = getReferenceID();
			} else {
				updateObject['customer_id'] = cookie.load('UserId');
			}
			
			var flgText = '';
			axios.post(apiUrlV2 + "cateringcart/delete", qs.stringify(updateObject))
			.then(res => {
				if (res.data.status === "ok") {
					flgText = 'success';
				} else {
					flgText = 'error';
				}
			})
			.catch(function (error) {
				console.log(error);
				flgText = 'error';
			});
			
			return flgText;
	}
	
	goBckFun() {
		this.props.prpActiveTab(4);
		$("#PackageEditMdl").modal("hide");
	}
	
	/* ViewProducts */

    render() {
		    
        return (<div>
					<div className="modal fade commom-modal-topcls packagemdlCls" id="PackageEditMdl">
						<div className="modal-dialog modal-lg">
							<div className="modal-content">
											
								<div className="modal-body">
								  
								  <div className="common-modal-head">
									  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">X</button>
									  <div className="Cart-list-mdlheader"><h2>Update Your Package</h2></div>
								  </div>
								  
								  {/* modal-items-box start */}
								  <div className="modal-items-box">
								  
									<div className="pkselect_tabsec">
										<div className="tab-content">
										{ this.getProductListHtml_inEd() }
										</div>
									</div>
									
								  </div>
								  {/* modal-items-box end */}
								  
								  
							   </div>
							   
							   {/*<div id="dvLoading" className="editDivLoadingCls"></div>*/}
						  </div>
						</div>
					</div>
				</div>);
     }
}
