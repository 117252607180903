/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import footerLogo from "../../common/images/logo.png";
import facebook from "../../common/images/facebook.svg";
import instagram from "../../common/images/instagram.svg";
import youtube from "../../common/images/youtube.svg";

import { GET_STATIC_BLOCK } from "../../actions";
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "" };
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="container">
          <div className="footer-top">
            <div className="footer-newletter">
              <span>Sign up for our newsletter</span>
              <h4>
                Be the First to Get the Scoop on Our Freshest Deals, Launches &
                Recipes!
              </h4>
              <div className="f-newletter-form">
                <input type="text" placeholder="Email Address" />
                <button type="submit" className="button">
                  Subscribe
                </button>
              </div>
              <div className="f-newletter-bottom">
                <input type="checkbox" />
                <label>
                  {" "}
                  By signing up, i agree to receive communications about Food
                  Barn’s services store events and matters via email and i have
                  also acknowledge & agreed to Food Barn’s Privacy Policy and
                  Terms & Conditions
                </label>
              </div>
            </div>
            {this.state.footerlink}
            <div className="mobile-footer-nav">
              <Accordion>
                <AccordionItem title="About Us">
                  <ul>
                    <li>
                      <a href="/our-story">Our Story</a>
                    </li>
                    <li>
                      <a href="/locations">Visit Us</a>
                    </li>
                  </ul>
                </AccordionItem>
                <AccordionItem title="Helpful Links">
                  <ul>
                    {/* <li>
                      <a href="#">Delivery Information</a>
                    </li> */}
                    <li>
                      <Link to={"/faq"}>FAQ</Link>
                    </li>
                    <li>
                      <Link to={"/terms-and-conditions"}>
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                  </ul>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <p>Copyright {yearSp} Tigga Folks.</p>
        </div>

        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <span>
              <i className="fa fa-angle-double-up ars"></i>
              <i className="fa fa-angle-up ars1"></i>
              <i className="fa fa-angle-up ars2"></i>
            </span>
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
