/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import close from "../../common/images/close.png";
import package_filter from "../../common/images/package-filter.png";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = { catgory_name: "", subcatgory_name: "" };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    // if(this.props.productData && this.props.productData !== nextProps.productData){
    //   if(nextProps.productData.result_set.length === 1){
    //     var sub_cate = nextProps.productData.result_set[0].product_subcategory_id;
    //   }
    // }
  }

  componentDidUpdate(prevProps) {
    let frstsubcate,
      frstcate = "";
    let category_name = "";
    let subcategory_name = "";
    let parameter = "";
    if (
      this.props.categoryData &&
      this.props.categoryData !== prevProps.categoryData
    ) {
      frstsubcate =
        this.props.categoryData.result_set[0].subcategory[0].pro_subcate_id;
      frstcate = this.props.categoryData.result_set[0].pro_cate_id;

      if (this.props.location.state) {
        $("#" + this.props.location.state.category_id).addClass("active");
        parameter =
          "&subcategory_id=" +
          this.props.location.state.category_id +
          "&product_is_combo='Yes'";
        $("#" + this.props.location.state.category_id)
          .parent()
          .parent()
          .parent()
          .addClass("active");
      } else {
        $("#" + frstcate).addClass("active");
        $("#" + frstsubcate).addClass("active");
        parameter =
          "&subcategory_id=" + frstsubcate + "&product_is_combo='Yes'";
      }
      this.props.getProduct(parameter);
      category_name = this.props.categoryData.result_set[0].pro_cate_name;
      subcategory_name =
        this.props.categoryData.result_set[0].subcategory[0].pro_subcate_name;
      this.setState({ cname: category_name, subname: subcategory_name });
    }
  }

  getSubcategoryproduct = (
    pro_subcate_id,
    category_name,
    subcategory_name,
    category_id
  ) => {
    $(document).ready(function () {
      $(".no-recrds-found").show();
      $(".products_list_content").hide();
    });
    $(".products_sidebar_inner li a, .products_sidebar_content").removeClass(
      "active"
    );

    $(
      ".products_sidebar_inner #" +
        category_id +
        ", .products_sidebar_inner #" +
        pro_subcate_id
    ).addClass("active");

    //$('.products_list_inner').hide();
    const parameter =
      "&subcategory_id=" + pro_subcate_id + "&product_is_combo='Yes'";
    this.props.getProduct(parameter);
    this.setState({
      cname: category_name,
      subname: subcategory_name,
      category_id: category_id,
      subcategory_id: pro_subcate_id,
    });
    $(".close_filter").trigger("click");
    return false;
  };

  render() {
    let categoryData = [];
    if (this.props.categoryData) {
      categoryData = this.props.categoryData.result_set;
    }

    return (
      <div className="products_sidebar">
        <div className="product_sidebar_top">
          <h4>{this.state.cname}</h4>
          <p>{this.state.subname}</p>
          <span className="package_filter">
            <img src={package_filter} alt="package_filter" />
            <img src={close} alt="close" className="closed" />
          </span>
        </div>
        <div className="products_sidebar_inner">
          {(categoryData || []).map((category, index) => {
            return (
              <div
                className="products_sidebar_content"
                key={index}
                id={category.pro_cate_id}
              >
                <h4>{category.pro_cate_name}</h4>
                <ul className="clear_ul category_listing">
                  {(category.subcategory || []).map((subcategory, index) => {
                    return (
                      <li key={index} data-index={index}>
                        <Link
                          to="#"
                          className=""
                          onClick={this.getSubcategoryproduct.bind(
                            this,
                            subcategory.pro_subcate_id,
                            category.pro_cate_name,
                            subcategory.pro_subcate_name,
                            category.pro_cate_id
                          )}
                          id={subcategory.pro_subcate_id}
                        >
                          {subcategory.pro_subcate_name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Category;
