/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import outletslider from "../../common/images/outletslider.jpg";

import clientimage from "../../common/images/client-1.jpg";
import clientimageone from "../../common/images/client-2.jpg";
import clientimagetwo from "../../common/images/client-3.jpg";
import clientimagethree from "../../common/images/client-4.jpg";
import clientimagefour from "../../common/images/client-5.jpg";

import { GET_STATIC_BLOCK, GET_BANNER_LIST } from "../../actions";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import Testimonial from "../Home/HomeSubCompo/Testimonial";
import Slider from "react-slick";

var Parser = require("html-react-parser");
const settingsJourney = {
  items: 1,
  autoplay: false,
  loop: true,
  nav: true,
};

var settingsourclient = {
  items: 6,
  nav: false,
  autoplay: true,
  dots: true,
  margin: 0,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};
class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staticblacks: [],
      homejournyBlk: "",
      partnerBlk: "",
      partnerimgpath: "",
      partnerimg: "",
      homejournyimgBlk: [],
    };
    this.props.getStaticBlock();
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".dvLoadrCls").hide();
    }, 2000);
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var homejournyBlk = "",
        homejournyimgpath = "",
        homecafe = "",
        partnerBlk = "",
        partnerimgpath = "",
        partnerimg = [];
      var homejournyimgBlk = [];
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "home-our-journey") {
            homejournyBlk = data.staticblocks_description;
            homejournyimgBlk = data.gallery_images;
            homejournyimgpath = data.gallery_image_path;
            return "";
          }
          if (data.staticblocks_slug === "home-order-now") {
            homecafe = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "our-shop-partner") {
            partnerBlk = data.staticblocks_description;
            partnerimgpath = data.gallery_image_path;
            partnerimg = data.gallery_images;
            return "";
          }
          partnerBlk;
        });
      }

      homejournyBlk =
        homejournyBlk !== "" && homejournyBlk !== null
          ? Parser(homejournyBlk)
          : homejournyBlk;

      homecafe =
        homecafe !== "" && homecafe !== null ? Parser(homecafe) : homecafe;

      partnerBlk =
        partnerBlk !== "" && partnerBlk !== null
          ? Parser(partnerBlk)
          : partnerBlk;

      this.setState({
        staticblacks: PropsData.staticblack,
        homejournyBlk: homejournyBlk,
        homejournyimgBlk: homejournyimgBlk,
        homejournyimgpath: homejournyimgpath,
        homecafe: homecafe,
        partnerBlk: partnerBlk,
        partnerimg: partnerimg,
        partnerimgpath: partnerimgpath,
      });
    }
  }

  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="about-banner-img">
          <img src={outletslider} alt="" />
        </div>

        <div className="aboutus-page">
          <div className="container-one cms-content">
            <div className="container cms-content">
              {this.state.homejournyBlk !== "" && (
                <section className="home-our-journey">
                  <div className="container">
                    <div className="hoj-inner">
                      {this.state.homejournyBlk}
                      {this.state.homejournyimgBlk.length > 0 && (
                        <div className="hoj-rhs">
                          <OwlCarousel options={settingsJourney}>
                            {this.state.homejournyimgBlk.map((item, index) => {
                              if (item !== "") {
                                return (
                                  <div key={index}>
                                    <img
                                      src={this.state.homejournyimgpath + item}
                                    />
                                  </div>
                                );
                              }
                            })}
                          </OwlCarousel>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              )}

              {this.state.partnerBlk !== "" && (
                <section class="featuredpro-section">
                  <div class="featured-products-section">
                    <div class="container">
                      {this.state.partnerBlk}
                      {this.state.partnerimg.length > 0 && (
                        <div className="about-us-our-client">
                          <OwlCarousel options={settingsourclient}>
                            {this.state.partnerimg.map((item, index) => {
                              if (item !== "") {
                                return (
                                  <div
                                    key={index}
                                    className="about-us-our-client-image"
                                  >
                                    <img
                                      src={this.state.partnerimgpath + item}
                                    />
                                  </div>
                                );
                              }
                            })}
                          </OwlCarousel>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              )}
              <Testimonial />
            </div>
          </div>
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return { staticblack: blacksArr };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

Aboutus.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Aboutus)
);
